import React from 'react';
import { isEmpty } from 'lodash';

import BudgetsService from '@/services/budgets_service';
import PasteLink from '@/components/paste_link';

export default function PasteBudgetLink({ budgetId }) {
  const placeholder = 'CategoryCode\tCategoryName\tGroupCode\tGroupName\tCategoryAmount\n'
    + 'A1\tComputers\tG1\tIT\t123.56\n'
    + 'A2\tServers\tG1\tIT\t56.78\n'
    + 'A3\tOther\t\t\t12.3\n';

  const fieldsMap = {
    name: {
      columns: ['category name', 'categoryname', 'category_name', 'name'],
      label: I18n.t('paste_items.budget.category_name'),
      constraints: { presence: { allowEmpty: false } },
    },
    code: {
      columns: ['category code', 'categorycode', 'category_code', 'code'],
      label: I18n.t('paste_items.budget.category_code'),
    },
    group_name: {
      columns: ['group name', 'groupname', 'group_name'],
      label: I18n.t('paste_items.budget.group_name'),
      constraints: (_value, attributes) => {
        if (isEmpty(attributes.group_code)) return null;
        return {
          presence: { allowEmpty: false },
        };
      },
    },
    group_code: {
      columns: ['group code', 'groupcode', 'group_code'],
      label: I18n.t('paste_items.budget.group_code'),
    },
    amount: {
      columns: ['price', 'amount', 'value', 'category amount', 'categoryamount'],
      label: I18n.t('paste_items.budget.amount'),
      constraints: { presence: { allowEmpty: false }, numericality: true },
    },
  };

  const importBudget = categories => BudgetsService.importAll(budgetId, categories);

  return (
    <React.Fragment>
      <PasteLink
        type="budget_categories"
        templateUrl="/example_budget_categories_file.xlsx"
        placeholder={placeholder}
        fieldsMap={fieldsMap}
        onImport={importBudget}
        afterImport="reload"
      >
        <a className="link">
          { I18n.t('purchase_orders.form.paste_items.import') }
        </a>
      </PasteLink>
    </React.Fragment>
  );
}
