import React from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';

const EditableRow = ({ 
  row, 
  columns, 
  isEditing, 
  onSave, 
  onCancel, 
  onEdit, 
  onDelete, 
  isLoading,
  isInOperation
}) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: row
  });

  const handleCancel = () => {
    reset(row);
    onCancel();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(onSave)(e);
    } else if (e.key === 'Escape') {
      handleCancel();
    }
  };

  if (isEditing) {
    return (
      <div className="tr editable-row">
        {columns.map((column, index) => (
          <div key={`edit-${row.id}-${column.id}`} className="td editable">
            {column.editable ? (
              <>
                <input
                  className="input-table"
                  type={column.type || 'text'}
                  min={column.min}
                  step={column.step}
                  disabled={isLoading}
                  {...register(column.id, column.validation)}
                  onKeyDown={handleKeyDown}
                  autoFocus={index === 1}
                />
                {errors[column.id] && (
                  <div className="has-error">
                    <div className="error">
                      <div className="hint error">{errors[column.id].message}</div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              row[column.id]
            )}
          </div>
        ))}
        <div className="td actions">
          <a className="save" onClick={handleSubmit(onSave)} disabled={isLoading}>
            <i className="icon-check"></i>
          </a>
          <a className="cancel" onClick={handleCancel}>
            <i className="icon-close"></i>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={cn('tr', { 'disabled': isInOperation })} id={row.id}>
      {columns.map(column => (
        <div key={`view-${row.id}-${column.id}`} className="td">
          {row[column.id]}
        </div>
      ))}
      <div className="td actions hoverable">
        {onEdit && (
          <a className={cn('edit', { 'disabled': isInOperation })} onClick={onEdit}>
            <i className="icon-edit"></i>
          </a>
        )}
        {onDelete && (
          <a className={cn('delete', { 'disabled': isInOperation })} onClick={onDelete}>
            <i className="icon-delete"></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default EditableRow; 