import React, { useState, useEffect } from 'react';
import SupplierInvoiceAttachmentModel from '../../models/SupplierInvoiceAttachmentModel';
import SupplierInvoicesService from '../../services/SupplierInvoicesService';
import { errorToast } from '../shared/toast';
import UploadAttachmentModal from './modals/upload_attachment_modal';

function ImageViewer({ supplier_invoice: { id, attachments } }) {
  useEffect(() => {
    setFiles(attachments);
    if (attachments !== undefined) {
      setSelectedFile(attachments[0]);
    }
  }, [attachments]);

  const [files, setFiles] = useState(attachments);
  const [selectedFile, setSelectedFile] = useState(
    new SupplierInvoiceAttachmentModel({ url: '' }),
  );
  const [width, setWidth] = useState(1000);
  const [isUploadAttachmentModalOpen, setIsUploadAttachmentModalOpen] = useState(false);

  const zoomIn = () => {
    setWidth(width + 100);
  };

  const zoomOut = () => {
    if (width > 100) setWidth(width - 100);
  };

  const rotate = () => {
    if (selectedFile?.transformValue === 0) {
      selectedFile.transform = `rotate(${270}deg)`;
      selectedFile.transformValue = 270;

      setSelectedFile(selectedFile);
      updateFile(selectedFile);
    } else {
      const oldTransform = selectedFile?.transformValue;
      selectedFile.transform = `rotate(${oldTransform - 90}deg)`;
      selectedFile.transformValue = oldTransform - 90;
      setSelectedFile(selectedFile);
      updateFile(selectedFile);
    }
  };

  const print = (e) => {
    e.preventDefault();

    SupplierInvoicesService.print(id)
      .then((pdf) => {
        const binaryString = atob(pdf);
        const len = binaryString.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i += 1) {
          view[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([view], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');

        iframe.src = fileURL;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(() => {
        errorToast(I18n.t('commons.messages.error'));
      });
  };

  const openUploadAttachmentModal = () => {
    setIsUploadAttachmentModalOpen(true);
  };

  const closeUploadAttachmentModal = () => {
    setIsUploadAttachmentModalOpen(false);
  };

  const addAttachments = (newAttachments) => {
    setFiles(oldAttachments => [...oldAttachments, ...newAttachments]);
  };

  const updateFile = (newFile) => {
    const newFiles = files.map((file) => {
      if (file.uuid === newFile.uuid) {
        return newFile;
      }
      return file;
    });

    setFiles(newFiles);
  };

  const onRightSwich = () => {
    files.map((file, index) => {
      if (selectedFile.uuid === file.uuid) {
        if (index < files.length - 1) {
          setSelectedFile(files[index + 1]);
        } else if (index < files.length) {
          setSelectedFile(files[0]);
        }
      }
    });
  };

  const onLeftSwich = () => {
    for (let index = files.length - 1; index >= 0; index -= 1) {
      if (selectedFile.uuid === files[index].uuid) {
        if (index > 0) {
          setSelectedFile(files[index - 1]);
        } else if (index === 0) {
          setSelectedFile(files[files.length - 1]);
        }
      }
    }
  };

  const renderDots = () => {
    if (files === undefined) return <a />;
    return files.map((file) => {
      if (file.uuid === selectedFile.uuid) {
        return (
          <a
            key={file.uuid}
            className="active"
            onClick={() => setSelectedFile(file)}
          />
        );
      }
      return <a key={file.uuid} onClick={() => setSelectedFile(file)} />;
    });
  };

  const renderArrow = files?.length > 1;

  return (
    <React.Fragment>
      <div className="window-frame-content window-editor ct-w-full">
        <div className="window-editor-actions">
          <div className="window-fixed">
            {/* <a className="icon-add" /> */}
            <a className="icon-zoom_in" onClick={zoomIn} />
            <a className="icon-zoom_out" onClick={zoomOut} />
            <a className="icon-rotate_left" onClick={rotate} />
            <a
              className="icon-download"
              href={`/api/accountings/supplier_invoices/${id}/download`}
            />
            <a className="icon-print" onClick={print} />
            <a className="icon-add" onClick={openUploadAttachmentModal} />
            {/* <a className="icon-ocr" /> */}
            {/* <a className="icon-delete" /> */}
          </div>
        </div>
        <div className="mobile-wrapper">
          {renderArrow ? (
            <a className="window-editor-nav-left">
              <span className="window-fixed">
                <i className="icon-keyboard_arrow_left" onClick={onLeftSwich} />
              </span>
            </a>
          ) : null}
          <div className="window-editor-dots">
            <div className="window-fixed">{renderDots()}</div>
          </div>
          {renderArrow ? (
            <a className="window-editor-nav-right">
              <span className="window-fixed">
                <i
                  className="icon-keyboard_arrow_right"
                  onClick={onRightSwich}
                />
              </span>
            </a>
          ) : null}
        </div>
        <div className="window-frame-content-wrapper">
          <div
            className="document-wrapper"
            style={{ width: `${width}px`, transform: selectedFile?.transform }}
          >
            <img src={selectedFile.url} />
          </div>
        </div>
      </div>
      {isUploadAttachmentModalOpen && (
        <UploadAttachmentModal
          isOpen={isUploadAttachmentModalOpen}
          close={closeUploadAttachmentModal}
          id={id}
          addAttachments={addAttachments}
        />
      )}
    </React.Fragment>
  );
}

export default ImageViewer;
