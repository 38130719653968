import { DateTime } from 'luxon';
import { toString, toNumber } from 'lodash';

export function parseDate(time, format = 'dd MMM yyyy') {
  if (!time) return '';

  return DateTime.fromISO(time).toFormat(format);
}

export function formatToCurrency(value) {
  const nf = new Intl.NumberFormat('no', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return nf.format(Number(value));
}

export function formatToPercentage(num) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
}

export function timeAgoInWords(time) {
  if (!time) return '';

  const givenTime = DateTime.fromISO(time);
  const currentTime = DateTime.local();
  const diff = currentTime.diff(givenTime, ['years', 'months', 'days', 'hours']);

  return DateTime.local().minus(diff).setLocale(I18n.locale).toRelative();
}

export function formatNumber(value) {
  return I18n.toNumber(value, { delimiter: ' ', precision: 2 });
}

export function addFilters(filters, { name, value }) {
  const stringValue = value.toString();
  const newFilters = filters;

  if (newFilters[name]) newFilters[name].push(stringValue);
  else newFilters[name] = [stringValue];

  return newFilters;
}

export function removeFilters(filters, { name, value }) {
  const stringValue = value.toString();
  let newFilters = filters;

  if (filters[name]?.length > 1) {
    newFilters = { ...newFilters, [name]: newFilters[name].filter(val => val !== stringValue) };
  } else {
    delete newFilters[name];
  }

  return newFilters;
}

export const valueToNumber = (value) => {
  const valueToConvert = toString(value).trim().replace(',', '.');
  const returnedValue = toNumber(valueToConvert);
  if (Number.isFinite(returnedValue)) return Math.round(returnedValue * 100) / 100;
  return 0;
};

// formats numbers with K/M/B/T suffixes.
export const getNumberWithSuffix = (num) => {
  const number = Number(num);
  if (!Number.isFinite(number)) return '0';

  if (Math.abs(number) < 1000) return number.toString();

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const tier = Math.floor(Math.log10(Math.abs(number)) / 3);

  let scaled = number / (10 ** (tier * 3));

  // use one decimal place and truncate instead of rounding
  scaled = Math.floor(Math.abs(scaled) * 10) / 10 * Math.sign(scaled);

  return `${scaled}${suffixes[tier]}`;
};

export default {};
