import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import Attachment from './attachments/attachment';
import AttachmentModal from './attachments/attachment_modal';
import * as api from '../shared/api';
import * as toast from '../shared/toast';

class Attachments extends React.Component {
  static propTypes = {
    modal: PropTypes.object,
    attachments: PropTypes.array,
  }

  constructor(props) {
    super(props);

    const { attachments, externalFilesLimitRemaining } = props;
    this.state = {
      attachments,
      internal: [],
      external: [],
      modal: {
        isOpen: false,
        type: '',
      },
      expand: 'internal',
      externalFilesLimitRemaining,
    };
  }

  componentDidMount() {
    this.updateAttachments();
  }

  expand = (e) => {
    this.setState({ expand: e.target.value });
  }

  updateAttachments = () => {
    const { attachments } = this.state;
    const external = attachments.filter(attachment => (attachment.category == 'external'));
    const internal = attachments.filter(attachment => (attachment.category == 'internal'));
    this.setState({
      external,
      internal,
    });
  }

  prepareActivity = ({ user, activity }) => ({
    user,
    id: activity.id,
    body: activity.body,
    activity_type: activity.activity_type,
    created_at: activity.created_at,
    po_number: activity.po_number,
  })

  moveAttachmentTo = (to, attachmentComponent) => {
    const { props: { addActivity }, state: { attachments } } = this;
    api.makePutRequest(`files/${attachmentComponent.props.attachment.id}`, { purchase_order_file: { category: to } })
      .then((response) => {
        const { data } = response;
        const newAttachments = _.filter(attachments, x => x.id != attachmentComponent.props.attachment.id);

        this.setState({
          attachments: newAttachments.concat([data.file]),
          externalFilesLimitRemaining: data.external_files_limit_remaining,
        });
        attachmentComponent.setState({ category: to });
        this.updateAttachments();
        addActivity(this.prepareActivity(data));
        toast.successToast(I18n.t('attachments.messages.moved'));
      }).catch((error) => {
        toast.errorToast(error.response.data.errors[0]?.description);
      });
  }

  deleteAttachment = (attachmentComponent) => {
    const { props: { addActivity }, state: { attachments } } = this;
    api.makeDeleteRequest(`files/${attachmentComponent.props.attachment.id}`)
      .then((response) => {
        const { data } = response;
        const newAttachments = _.filter(attachments, x => x.id != attachmentComponent.props.attachment.id);

        this.setState({ attachments: newAttachments }, () => { this.updateAttachments(); });
        addActivity(this.prepareActivity(data));
        toast.successToast(I18n.t('attachments.messages.deleted'));
      });
  }

  addAttachments = (uploadResponse) => {
    const {
      files, activities, external_files_limit_remaining, user,
    } = uploadResponse;
    const { addAttachment, prepareActivity, props: { addActivity } } = this;
    files.forEach(attachment => addAttachment(attachment));
    activities.forEach(activity => addActivity(prepareActivity({ user, activity })));
    this.setState({ externalFilesLimitRemaining: external_files_limit_remaining });
  }

  addAttachment = (attachment) => {
    const { state: { attachments } } = this;
    this.setState({ attachments: attachments.concat([attachment]) }, () => { this.updateAttachments(); });
  }

  openAttachmentModal = () => {
    this.setState({ modal: { isOpen: true, type: 'external' } });
  }

  openInternalModal = () => {
    this.setState({ modal: { isOpen: true, type: 'internal' } });
  }

  closeAttachmentModal = () => {
    this.setState({ modal: { isOpen: false } });
  }

  render() {
    const {
      internal, external, expand, modal, externalFilesLimitRemaining,
    } = this.state;
    const {
      onSidebarToggle, externalFilesLimitReached, externalFilesLimitSize,
      confirmationFile, openConfirmationModal, disconfirmOrder,
    } = this.props;

    return (
      <div className="tabs">
        <input checked={expand == 'internal'} value="internal" id="create-po-attachments" name="create-po-tabs" type="radio" onChange={this.expand} />
        <input checked={expand == 'external'} value="external" id="create-po-internal" name="create-po-tabs" type="radio" onChange={this.expand} />
        <div className="tab-navs">
          <a className="sidebar-toggle" onClick={onSidebarToggle}>
            <i className="icon-close" />
          </a>
          <div className="tab-labels grid">
            <label className="cell" htmlFor="create-po-attachments">
              <span>{I18n.t('purchase_orders.form.attachments')}</span>
            </label>
            <label className="cell" htmlFor="create-po-internal">
              <span>{I18n.t('purchase_orders.form.internal_files')}</span>
            </label>
          </div>
        </div>
        <div className="tab-pages">
          <div>
            <ul className="items menu upload-items scroll">
              {external.map((attachment, i) => (
                <Attachment key={attachment.id} arrayIndex={i} style="attach_file" attachment={attachment} moveAttachmentTo={this.moveAttachmentTo} deleteAttachment={this.deleteAttachment} />
              ))}
            </ul>
            <div className="hint text-right">
              <label className="link" onClick={this.openAttachmentModal}>
                {I18n.t('purchase_orders.form.upload_attachment_file')}
              </label>
            </div>
          </div>
          <div>
            <ul className="items menu upload-items scroll">
              {internal.map((attachment, i) => (
                <Attachment key={attachment.id} arrayIndex={i} style="internal_file" attachment={attachment} moveAttachmentTo={this.moveAttachmentTo} deleteAttachment={this.deleteAttachment} />
              ))}
              <Attachment key={uuid()} arrayIndex={internal.length} style="internal_file" attachment={confirmationFile} openConfirmationModal={openConfirmationModal} disconfirmOrder={disconfirmOrder} />
            </ul>
            <div className="hint text-right">
              <label className="link" onClick={this.openInternalModal}>
                {I18n.t('purchase_orders.form.upload_internal_file')}
              </label>
            </div>
          </div>
        </div>
        <AttachmentModal
          externalFilesLimitSize={externalFilesLimitSize}
          externalFilesLimitReached={externalFilesLimitReached}
          externalFilesLimitRemaining={externalFilesLimitRemaining}
          addAttachments={this.addAttachments}
          closeAttachmentModal={this.closeAttachmentModal}
          isOpen={modal.isOpen}
          type={modal.type}
        />
      </div>
    );
  }
}

export default Attachments;
