import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PaymentTermsService from '../services/payment_terms_service';
import EditableTable from './shared/EditableTable';
import * as Toast from './shared/toast';

const PaymentTermsDataTable = () => {
  const [terms, setTerms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const isInOperation = isAdding || editingId;

  const columns = [
    {
      id: 'id',
      label: I18n.t('settings.po_setup.partials.payment_terms.payment_terms_id'),
      editable: false,
    },
    {
      id: 'name',
      label: I18n.t('settings.po_setup.partials.payment_terms.name'),
      editable: true,
      validation: { required: 'Name is required' },
    },
    {
      id: 'value',
      label: I18n.t('settings.po_setup.partials.payment_terms.value'),
      editable: true,
      type: 'number',
      min: 0,
      step: 1,
      validation: { required: 'Value is required' },
    },
    {
      id: 'created_at',
      label: I18n.t('settings.po_setup.partials.payment_terms.created'),
      editable: false,
    },
  ];

  useEffect(() => {
    fetchPaymentTerms();
  }, []);

  const fetchPaymentTerms = async () => {
    try {
      setIsLoading(true);
      const response = await PaymentTermsService.fetchAll();
      setTerms(response.data.payment_terms);
    } catch (error) {
      Toast.errorToast(I18n.t('settings.po_setup.partials.payment_terms.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (formData) => {
    try {
      setIsLoading(true);
      const response = editingId
        ? await PaymentTermsService.update(editingId, formData)
        : await PaymentTermsService.create(formData);

      const updatedTerm = response.data.payment_term;

      setTerms((prevTerms) => {
        if (editingId) {
          return prevTerms.map(t => (t.id === editingId ? updatedTerm : t));
        }
        return [...prevTerms, updatedTerm];
      });

      Toast.successToast(I18n.t('settings.po_setup.partials.payment_terms.saved'));
    } catch (error) {
      Toast.errorToast(I18n.t('settings.po_setup.partials.payment_terms.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (termId) => {
    if (!window.confirm(I18n.t('settings.po_setup.partials.payment_terms.are_you_sure'))) return;

    try {
      setIsLoading(true);
      await PaymentTermsService.delete(termId);
      setTerms(prevTerms => prevTerms.filter(term => term.id !== termId));
      Toast.successToast(I18n.t('settings.po_setup.partials.payment_terms.deleted'));
    } catch (error) {
      Toast.errorToast(I18n.t('settings.po_setup.partials.payment_terms.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = () => {
    if (isLoading || isInOperation) return;
    setIsAdding(true);
  };

  return (
    <div className="tile with-table ct-flex ct-flex-col ct-overflow-hidden ct-max-h-full">
      <div className="table-actions">
        <div className="right-actions">
          <div className="actions items items-divided">
            <a
              className={cn('link', { 'link--disabled': isInOperation })}
              onClick={handleAdd}
            >
              {I18n.t('settings.po_setup.partials.payment_terms.add_new_payment_term')}
            </a>
          </div>
        </div>
      </div>

      <EditableTable
        columns={columns}
        data={terms}
        onSave={handleSave}
        onDelete={handleDelete}
        isLoading={isLoading}
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        editingId={editingId}
        setEditingId={setEditingId}
      />

      <div className="tile-footer">
        <div className="actions">
          <a
            className={cn('link', { 'link--disabled': isInOperation })}
            onClick={handleAdd}
          >
            {I18n.t('settings.po_setup.partials.payment_terms.add_new_payment_term')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentTermsDataTable;
