import {
  makeGetRequest, makePostRequest, makePutRequest, makeDeleteRequest,
} from '../components/shared/api';

class PaymentTermsService {
  static fetchAll() {
    return makeGetRequest('/api/payment_terms');
  }

  static create(params) {
    return makePostRequest('/api/payment_terms', { payment_term: params });
  }

  static update(id, params) {
    return makePutRequest(`/api/payment_terms/${id}`, { payment_term: params });
  }

  static delete(id) {
    return makeDeleteRequest(`/api/payment_terms/${id}`);
  }
}

export default PaymentTermsService;
