import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import { withLimit } from '../shared/utils';
import CustomField from '../shared/custom_field';
import SearchDropdown from './internal_info/SearchDropdown';

class InternalInfo extends React.Component {
  static propTypes = {
    budgets: PropTypes.array,
    purchaseOrder: PropTypes.object,
    department: PropTypes.object,
    saveDraft: PropTypes.func,
    openSupplierMessageModal: PropTypes.func,
    autosendError: PropTypes.array,
    projects: PropTypes.array,
    dateFormat: PropTypes.string,
    numberFormat: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onChangeBudgetName = this.onChangeBudgetName.bind(this);
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.onAutosendChange = this.onAutosendChange.bind(this);
    this.setAutosendOff = this.setAutosendOff.bind(this);
    this.updateCurrency = this.updateCurrency.bind(this);
    this.unselectProject = this.unselectProject.bind(this);

    this.mainCurrencies = props.currencies;
    this.mainProjects = props.projects;
    this.mainCustomers = props.customers;
    this.mainSalesOrders = props.sales_orders;
    this.mainAccounts = props.accounts;
    this.dateFormat = props.dateFormat;
    this.numberFormat = props.numberFormat;

    this.state = {
      budget_id: props.purchaseOrder.budget_id,
      project_id: props.purchaseOrder.project_id,
      autosend: props.purchaseOrder.autosend,
      department: props.department,
      currency: props.currency,
      currencies: props.currencies,
      searchValue: '',
      searchProjectValue: '',
      searchSalesOrderValue: '',
      typingTimeout: 0,
      projects: props.projects,
      accounts: props.accounts,
      customers: withLimit(props.customers, props.purchaseOrder.customer_id),
      sales_orders: withLimit(props.sales_orders, props.purchaseOrder.sales_order_id),
      monthsNumber: props.purchaseOrder.months_number,
      customerUplift: props.purchaseOrder.customer_uplift,
      endDate: props.purchaseOrder.end_date,
      monthlyCost: props.purchaseOrder.monthly_cost,
      monthlySalesPrice: props.purchaseOrder.monthly_sales_price,
      oneOffSalesPrice: props.purchaseOrder.one_off_sales_price,
      projectLocked: _.find(props.budgets, b => b.id == props.purchaseOrder.budget_id)?.project_id,
    };
  }

  onChangeSearchProject = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchProjectValue: e.target.value,
      typingTimeout: setTimeout(() => {
        const { searchProjectValue } = this.state;
        this.setState({
          projects: this.mainProjects.filter(project => project.name.toLowerCase().includes(searchProjectValue.toLowerCase())),
        });
      }, 350),
    });
  };

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChangeSearchCurrency = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchValue: e.target.value,
      typingTimeout: setTimeout(() => {
        const { searchValue } = this.state;
        this.setState({
          currencies: this.mainCurrencies.filter(currency => currency.toLowerCase().includes(searchValue.toLowerCase())),
        });
      }, 350),
    });
  };

  onChangeSearchCustomer = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchCustomerValue: e.target.value,
      typingTimeout: setTimeout(() => {
        const {
          state: { searchCustomerValue },
          props: { purchaseOrder },
        } = this;
        this.setState({
          customers: withLimit(
            this.mainCustomers.filter(
              customer => customer.id === purchaseOrder.customer_id
                || customer.name.toLowerCase().includes(searchCustomerValue.toLowerCase()),
            ),
          ),
        });
      }, 350),
    });
  };

  onChangeSearchSalesOrder = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchSalesOrderValue: e.target.value,
      typingTimeout: setTimeout(() => {
        const {
          state: { searchSalesOrderValue },
          props: { purchaseOrder },
        } = this;
        this.setState({
          sales_orders: withLimit(
            this.mainSalesOrders.filter(
              sales_order => sales_order.id === purchaseOrder.sales_order_id
                || sales_order.name.toLowerCase().includes(searchSalesOrderValue.toLowerCase()),
            ),
          ),
        });
      }, 350),
    });
  };

  onChangeSearchAccounts = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchAccountsValue: e.target.value,
      typingTimeout: setTimeout(() => {
        const {
          state: { searchAccountsValue },
          props: { purchaseOrder },
        } = this;
        this.setState({
          accounts: withLimit(
            this.mainAccounts.filter(
              account => account.id === purchaseOrder.account_id
                || account.name.toLowerCase().includes(searchAccountsValue.toLowerCase())
                || account.number?.includes(searchAccountsValue),
            ),
          ),
        });
      }, 350),
    });
  };

  onChangeProject = (e) => {
    const { projects, saveDraft } = this.props;
    const { value } = e.target;
    const foundProject = projects.find(project => project.id === parseInt(value));

    if (foundProject) return this.setState({ project_id: foundProject.id }, () => saveDraft());
  };

  onChangeBudgetName(e) {
    const {
      state: { budget_id },
      props: {
        handleChangeCategoriesForBudget, budgets, purchaseOrder, handleCustomTypesChange,
      },
    } = this;

    let { value } = e.target;
    if (e.target.value == budget_id) value = undefined;
    const budget = _.find(budgets, b => b.id == value);
    this.setState({ department: { name: budget.department_name } });
    const projectId = budget.project_id;
    if (projectId) {
      this.setState({ projectLocked: true, project_id: projectId });
    } else {
      this.setState({ projectLocked: false, project_id: null });
    }
    this.setState({ budget_id: value }, () => {
      const { budget_id: newBudgetId } = this.state;
      handleChangeCategoriesForBudget(newBudgetId);
      if (purchaseOrder.custom_po_enabled) handleCustomTypesChange(budget);
    });
  }

  onCurrencyChange(e) {
    const { handleUpdateCurrency } = this.props;
    handleUpdateCurrency(e.target.value);
    this.setState({ currency: e.target.value });
  }

  onAutosendChange() {
    const {
      state: { autosend },
      props: { saveDraft, openSupplierMessageModal },
    } = this;
    this.setState({ autosend: !autosend }, () => {
      const { autosend: newAustosend } = this.state;
      if (newAustosend) {
        openSupplierMessageModal();
      } else {
        saveDraft();
      }
    });
  }

  onCustomFieldChange = (id, value, optionId = null) => {
    const { handleCustomFieldChange } = this.props;
    handleCustomFieldChange(id, value, optionId);
  };

  onTypeChange = (e) => {
    const { openTypeChangeModal } = this.props;
    openTypeChangeModal(e.target.value);
  };

  onSalesOrderChange = (e) => {
    const { setSalesOrder } = this.props;
    setSalesOrder(e.target.value);
  };

  onAccountChange = (e) => {
    const { setAccount } = this.props;
    setAccount(e.target.value);
  };

  onAccountDetach = () => {
    const { setAccount } = this.props;
    setAccount(null);
  };

  onCustomerChange = (e) => {
    const { setCustomer } = this.props;
    setCustomer(e.target.value);
  };

  onCustomerDetach = () => {
    const { setCustomer } = this.props;
    setCustomer(null);
  };

  onStartDateChange = (date) => {
    const {
      props: { setStartDate },
      calculateEndDateAndMonthlyCost,
    } = this;

    setStartDate(date);
    calculateEndDateAndMonthlyCost();
  };

  onMonthsNumberChange = (e) => {
    const {
      props: { setMonthsNumber },
      calculateEndDateAndMonthlyCost,
    } = this;

    setMonthsNumber(e.target.value);
    calculateEndDateAndMonthlyCost();
  };

  onCustomerLiftChange = (e) => {
    const {
      props: { purchaseOrder, setCustomerUplift },
      calculateMonthlySalesPrice,
      calculateOneOffSalesPrice,
    } = this;

    setCustomerUplift(e.target.value);
    if (purchaseOrder.type === 'customer_leasing') {
      this.setState({
        monthlySalesPrice: calculateMonthlySalesPrice(),
      });
    } else {
      this.setState({
        oneOffSalesPrice: calculateOneOffSalesPrice(),
      });
    }
  };

  onLanguageChange = (e) => {
    const { setPdfLanguage } = this.props;
    setPdfLanguage(e.target.value);
  };

  onMultitenantChange = (e) => {
    const { setMultitenant } = this.props;
    setMultitenant(e.target.value);
  };

  setAutosendOff() {
    this.setState({ autosend: false });
  }

  unselectProject = () => {
    const { saveDraft } = this.props;

    this.setState({ project_id: null }, () => saveDraft());
  }

  confirmTypeChange = () => {
    this.setState({
      customerUplift: null,
      monthsNumber: null,
      monthlyCost: null,
      monthlySalesPrice: null,
      oneOffSalesPrice: null,
      endDate: null,
    });
  };

  calculateEndDateAndMonthlyCost = () => {
    const { calculateEndDate, calculateMonthlyCost, calculateMonthlySalesPrice } = this;

    this.setState(
      {
        endDate: calculateEndDate(),
        monthlyCost: calculateMonthlyCost(),
      },
      () => this.setState({ monthlySalesPrice: calculateMonthlySalesPrice() }),
    );
  };

  formattedEndDate = () => {
    const { endDate } = this.state;

    if (endDate) {
      return moment(endDate).format(this.dateFormat);
    }
    return null;
  };

  formattedCost = (cost) => {
    const { thousands_separator, decimal_mark } = this.numberFormat;
    if (cost) {
      return I18n.toNumber(cost, { delimiter: thousands_separator, separator: decimal_mark, precision: 2 });
    }
    return null;
  };

  calculateMonthlyCost = () => {
    const { purchaseOrder, totalItemsValue, exchangeRate } = this.props;

    if (purchaseOrder.start_date && purchaseOrder.months_number) {
      return Math.round((totalItemsValue / exchangeRate / purchaseOrder.months_number) * 100) / 100;
    }
    return null;
  };

  calculateMonthlySalesPrice = () => {
    const {
      props: { purchaseOrder },
      state: { monthlyCost },
    } = this;

    if (monthlyCost && purchaseOrder.customer_uplift) {
      return monthlyCost * (1 + purchaseOrder.customer_uplift / 100);
    }
    return null;
  };

  calculateOneOffSalesPrice = () => {
    const { purchaseOrder, totalItemsValue, exchangeRate } = this.props;

    if (purchaseOrder.customer_uplift) {
      const oneOffCost = Math.round((totalItemsValue / exchangeRate) * 100) / 100;

      return oneOffCost * (1 + purchaseOrder.customer_uplift / 100);
    }
    return null;
  };

  calculateEndDate = () => {
    const { purchaseOrder } = this.props;

    if (purchaseOrder.start_date && purchaseOrder.months_number) {
      return moment(purchaseOrder.start_date)
        .add(purchaseOrder.months_number, 'months')
        .subtract(1, 'days')
        .format(this.dateFormat);
    }
    return null;
  };

  updateCurrency(currency) {
    this.setState({ currency });
  }

  filteredBudgets() {
    const { props: { budgets, purchaseOrder } } = this;

    const formatBudgetName = budget => ({
      ...budget,
      name: budget.code ? `${budget.code} - ${budget.name}` : budget.name,
    });

    if (!purchaseOrder.budget) {
      return budgets.map(formatBudgetName);
    }

    const updatedBudgets = purchaseOrder.budget.active ? budgets : [purchaseOrder.budget, ...budgets];

    return updatedBudgets.map(formatBudgetName);
  }

  render() {
    const {
      props: {
        purchaseOrder,
        exchangeRate,
        budgetError,
        autosendError,
        currencyError,
        saveDraft,
        currency: propsCurrency,
        salesOrderError,
        typeError,
        startDateError,
        monthsNumberError,
        customerError,
        fieldSettings,
        accountError,
        accountsEnabled,
        customerUpliftError,
        customFieldSettings,
        projects,
        projectError,
      },
      state: {
        budget_id,
        autosend,
        currency: stateCurrency,
        currencies,
        projectLocked,
        customers,
        sales_orders,
        monthsNumber,
        project_id,
        customerUplift,
        monthlyCost,
        monthlySalesPrice,
        oneOffSalesPrice,
        accounts,
      },
      formattedEndDate,
      formattedCost,
    } = this;

    const accountSettings = fieldSettings.filter(setting => setting.field_name === 'account')[0];

    return (
      <div>
        <div className="form-section-header">
          <span>{I18n.t('purchase_orders.form.internal_info')}</span>
        </div>
        <div className="form-section-content">
          <div className="grid space-2 gutter-2">
            <SearchDropdown
              id={parseInt(budget_id)}
              records={this.filteredBudgets()}
              error={budgetError}
              editable={purchaseOrder.editable}
              fieldName="budget"
              onChange={this.onChangeBudgetName}
              required
            />
            {fieldSettings.filter(setting => setting.field_name === 'project')[0].active ? (
              <SearchDropdown
                id={parseInt(project_id)}
                records={projects}
                error={projectError}
                editable={purchaseOrder.editable || projectLocked}
                fieldName="project"
                onChange={this.onChangeProject}
                unselectOption={this.unselectProject}
              />
            ) : null}
            {accountsEnabled && accountSettings && accountSettings.active && (
              <div className="row">
                <div className={`cell-16 ${accountError ? 'has-error' : ''}`}>
                  <div className={`as-input reduce ${accountSettings.required ? 'required' : ''}`}>
                    {purchaseOrder.account_id && (
                      <label tabIndex="-1" className="add-edit-trigger">
                        <i className="icon-close" onClick={this.onAccountDetach} />
                      </label>
                    )}
                    <span className="label">{I18n.t('purchase_orders.form.gl_account')}</span>
                    <span
                      className={
                        !purchaseOrder.editable ? 'select input-r add-edit disabled' : 'select input-r add-edit'
                      }
                      name="type"
                    >
                      <input type="checkbox" />
                      <div className="control">
                        <input
                          type="text"
                          tabIndex="-1"
                          onChange={this.onChangeSearchAccounts}
                          placeholder={I18n.t('purchase_orders.form.type_to_search')}
                        />
                      </div>
                      <div className="select-content">
                        {accounts.map(account => (
                          <React.Fragment key={account.id}>
                            <input
                              key={`input-account-${account.id}`}
                              id={`account_${account.id}`}
                              name="account_id"
                              type="radio"
                              value={account.id}
                              checked={purchaseOrder.account_id == account.id}
                              onChange={this.onAccountChange}
                            />
                            <label
                              className={`${purchaseOrder.account_id == account.id ? 'checked' : ''}`}
                              key={`label-${account.id}`}
                              htmlFor={`account_${account.id}`}
                            >
                              {account.number ? `${account.number} ${account.name}` : account.name}
                            </label>
                          </React.Fragment>
                        ))}
                        <div
                          className="placeholder"
                          data-placeholder={I18n.t('purchase_orders.form.select_gl_account')}
                        />
                      </div>
                    </span>
                    {accountError ? <div className="hint error">{accountError}</div> : ''}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className={`cell-8 ${currencyError ? 'has-error' : ''}`}>
                <div className="as-input reduce required">
                  <span className="label">{I18n.t('purchase_orders.form.currency')}</span>
                  <span
                    className={
                      !purchaseOrder.editable
                        ? 'select input-r add-edit selected disabled'
                        : 'select input-r add-edit selected'
                    }
                    name="currency_select"
                  >
                    <input type="checkbox" />
                    <div className="control icon-search">
                      <input
                        type="text"
                        tabIndex="-1"
                        onChange={this.onChangeSearchCurrency}
                        placeholder={I18n.t('purchase_orders.form.type_to_search')}
                      />
                    </div>
                    <div className="select-content">
                      <input
                        key={`input-${stateCurrency}`}
                        id={`currency_${stateCurrency}`}
                        onBlur={saveDraft}
                        name="currency"
                        type="radio"
                        value={stateCurrency}
                        checked={propsCurrency === stateCurrency}
                        onChange={this.onCurrencyChange}
                      />
                      <label
                        className={`${propsCurrency === stateCurrency ? 'checked' : ''}`}
                        key={`label-${stateCurrency}`}
                        htmlFor={`currency_${stateCurrency}`}
                      >
                        {stateCurrency}
                      </label>
                      {currencies
                        .filter(currency => currency !== stateCurrency)
                        .map(currency => (
                          <React.Fragment key={currency}>
                            <input
                              key={`input-${currency}`}
                              id={`currency_${currency}`}
                              onBlur={saveDraft}
                              name="currency"
                              type="radio"
                              value={currency}
                              checked={stateCurrency === currency}
                              onChange={this.onCurrencyChange}
                            />
                            <label key={`label-${currency}`} htmlFor={`currency_${currency}`}>
                              {currency}
                            </label>
                          </React.Fragment>
                        ))}
                    </div>
                    {currencyError ? <div className="hint error">{currencyError}</div> : ''}
                  </span>
                </div>
              </div>
              <div className="cell-8">
                <div className="as-input reduce">
                  <span className="label">{I18n.t('purchase_orders.form.pdf_language')}</span>
                  <span
                    className={!purchaseOrder.editable ? 'select input-r add-edit disabled' : 'select input-r add-edit'}
                    name="pdf_select"
                  >
                    <input type="checkbox" />
                    <div className="select-content">
                      {[
                        { shorthand: 'en', fullName: I18n.t('purchase_orders.form.english') },
                        { shorthand: 'nb', fullName: I18n.t('purchase_orders.form.norwegian') },
                        { shorthand: 'fr', fullName: I18n.t('purchase_orders.form.french') },
                        { shorthand: 'de', fullName: I18n.t('purchase_orders.form.german') },
                      ].map(language => (
                        <React.Fragment key={language.shorthand}>
                          <input
                            key={`input-${language.shorthand}`}
                            id={`pdf_language_${language.shorthand}`}
                            name="pdf_language"
                            type="radio"
                            value={language.shorthand}
                            checked={purchaseOrder.supplier_pdf_language == language.shorthand}
                            onChange={this.onLanguageChange}
                          />
                          <label
                            className={`${purchaseOrder.supplier_pdf_language == language.shorthand ? 'checked' : ''}`}
                            key={`label-${language.shorthand}`}
                            htmlFor={`pdf_language_${language.shorthand}`}
                          >
                            {language.fullName}
                          </label>
                        </React.Fragment>
                      ))}
                      <div
                        className="placeholder"
                        data-placeholder={I18n.t('purchase_orders.form.select_pdf_language')}
                      />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="cell-8">
                <div className="as-input row below">
                  <span className="label cell">{I18n.t('purchase_orders.form.exchange_rate')}</span>
                  <div className="output cell number">
                    {I18n.toNumber(exchangeRate, { delimiter: this.numberFormat.thousands_separator, separator: this.numberFormat.decimal_mark, precision: 3 })}
                  </div>
                </div>
              </div>
            </div>
            {purchaseOrder.custom_po_enabled && purchaseOrder.custom_types ? (
              <div className="row">
                <div className={`cell-16 ${typeError ? 'has-error' : ''}`}>
                  <div className="as-input reduce required">
                    <span className="label">{I18n.t('purchase_orders.form.type')}</span>
                    <span
                      className={
                        !purchaseOrder.editable ? 'select input-r add-edit disabled' : 'select input-r add-edit'
                      }
                      name="type"
                    >
                      <input type="checkbox" />
                      <div className="select-content">
                        {purchaseOrder.custom_types.map(custom_type => (
                          <React.Fragment key={custom_type}>
                            <input
                              key={`input-${custom_type}`}
                              id={`type_${custom_type}`}
                              name="type"
                              type="radio"
                              value={custom_type}
                              checked={purchaseOrder.type == custom_type}
                              onChange={this.onTypeChange}
                            />
                            <label
                              className={`${purchaseOrder.type == custom_type ? 'checked' : ''}`}
                              key={`label-${custom_type}`}
                              htmlFor={`type_${custom_type}`}
                            >
                              {I18n.t(`purchase_orders.form.${custom_type}`)}
                            </label>
                          </React.Fragment>
                        ))}
                        <div
                          className="placeholder"
                          data-placeholder={I18n.t('purchase_orders.form.select_purchase_type')}
                        />
                      </div>
                    </span>
                    {typeError ? <div className="hint error">{typeError}</div> : ''}
                  </div>
                </div>
              </div>
            ) : null}
            {purchaseOrder.custom_po_enabled && purchaseOrder.type === 'investments' ? (
              <div className="row">
                <div className="cell-16">
                  <div className="as-input reduce required">
                    <span className="label">{I18n.t('purchase_orders.form.multitenant')}</span>
                    <div className="output">
                      <div className="display-flex">
                        <label className="has-checkbox item">
                          <input
                            type="radio"
                            value="true"
                            checked={purchaseOrder.multitenant.toString() === 'true'}
                            onChange={this.onMultitenantChange}
                            disabled={!purchaseOrder.editable}
                          />
                          <span className="radio checkbox" />
                          <div className="label">Yes</div>
                        </label>
                        <label className="has-checkbox item">
                          <input
                            type="radio"
                            value="false"
                            checked={purchaseOrder.multitenant.toString() === 'false'}
                            onChange={this.onMultitenantChange}
                            disabled={!purchaseOrder.editable}
                          />
                          <span className="checkbox radio" />
                          <div className="label">No</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {purchaseOrder.type && (
              <div className="row">
                <div className={`cell-16 ${customerError ? 'has-error' : ''}`}>
                  <div className="as-input reduce required">
                    {purchaseOrder.customer_id ? (
                      <label className="add-edit-trigger">
                        <i className="icon-close" onClick={this.onCustomerDetach} />
                      </label>
                    ) : null}
                    <span className="label">{I18n.t('purchase_orders.form.customer')}</span>
                    <span
                      className={
                        !purchaseOrder.editable ? 'select input-r add-edit disabled' : 'select input-r add-edit'
                      }
                      name="type"
                    >
                      <input type="checkbox" />
                      <div className="control">
                        <input
                          type="text"
                          tabIndex="-1"
                          onChange={this.onChangeSearchCustomer}
                          placeholder={I18n.t('purchase_orders.form.type_to_search')}
                        />
                      </div>
                      <div className="select-content">
                        {customers.map(customer => (
                          <React.Fragment key={customer.id}>
                            <input
                              key={`input-customer-${customer.id}`}
                              id={`customer_${customer.id}`}
                              name="customer_id"
                              type="radio"
                              value={customer.id}
                              checked={purchaseOrder.customer_id == customer.id}
                              onChange={this.onCustomerChange}
                            />
                            <label
                              className={`${purchaseOrder.customer_id == customer.id ? 'checked' : ''}`}
                              key={`label-${customer.id}`}
                              htmlFor={`customer_${customer.id}`}
                            >
                              {customer.name}
                            </label>
                          </React.Fragment>
                        ))}
                        <div
                          className="placeholder"
                          data-placeholder={I18n.t('purchase_orders.form.select_customer')}
                        />
                      </div>
                    </span>
                    {customerError ? <div className="hint error">{customerError}</div> : ''}
                  </div>
                </div>
              </div>
            )}
            {purchaseOrder.custom_po_enabled
            && (purchaseOrder.type === 'customer_leasing'
              || purchaseOrder.type === 'third_party_resale'
              || (purchaseOrder.type === 'investments' && purchaseOrder.multitenant.toString() === 'false')) ? (
                <div className="row">
                  <div className={`cell-16 ${salesOrderError ? 'has-error' : ''}`}>
                    <div className="as-input reduce required">
                      <span className="label">{I18n.t('purchase_orders.form.sales_order')}</span>
                      <span
                        className={
                        !purchaseOrder.editable ? 'select input-r add-edit disabled' : 'select input-r add-edit'
                      }
                        name="type"
                      >
                        <input type="checkbox" />
                        <div className="control">
                          <input
                            type="text"
                            tabIndex="-1"
                            onChange={this.onChangeSearchSalesOrder}
                            placeholder={I18n.t('purchase_orders.form.type_to_search')}
                          />
                        </div>
                        <div className="select-content">
                          {sales_orders.map(order => (
                            <React.Fragment key={order.id}>
                              <input
                                key={`input-sales_order-${order.id}`}
                                id={`sales_order_${order.id}`}
                                name="sales_order_id"
                                type="radio"
                                value={order.id}
                                checked={purchaseOrder.sales_order_id == order.id}
                                onChange={this.onSalesOrderChange}
                              />
                              <label
                                className={`${purchaseOrder.salesforce_order_id == order.id ? 'checked' : ''}`}
                                key={`label-${order.id}`}
                                htmlFor={`sales_order_${order.id}`}
                              >
                                {order.name}
                              </label>
                            </React.Fragment>
                          ))}
                          <div
                            className="placeholder"
                            data-placeholder={I18n.t('purchase_orders.form.select_sales_order')}
                          />
                        </div>
                      </span>
                      {salesOrderError ? <div className="hint error">{salesOrderError}</div> : ''}
                    </div>
                  </div>
                </div>
              ) : null}
            {purchaseOrder.custom_po_enabled && purchaseOrder.type === 'customer_leasing' ? (
              <React.Fragment>
                <div className="row">
                  <div className={`cell-7 ${startDateError ? 'has-error' : ''}`}>
                    <div className="as-input reduce required">
                      <span className="label">{I18n.t('purchase_orders.form.start_date')}</span>
                      <DatePicker
                        selected={purchaseOrder.start_date ? moment(purchaseOrder.start_date) : null}
                        onChange={this.onStartDateChange}
                        readOnly={!purchaseOrder.editable}
                        dateFormat={this.dateFormat}
                        locale={I18n.locale}
                        className="output input-r"
                        placeholderText={I18n.t('purchase_orders.form.select_start_date')}
                      />
                    </div>
                    {startDateError ? <div className="hint error">{startDateError}</div> : ''}
                  </div>
                  <div className={`cell-9 ${monthsNumberError ? 'has-error' : ''}`}>
                    <div className="as-input reduce required">
                      <span className="label">{I18n.t('purchase_orders.form.months_number')}</span>
                      <input
                        type="number"
                        disabled={!purchaseOrder.editable}
                        name="monthsNumber"
                        placeholder={I18n.t('purchase_orders.form.add_months_number')}
                        value={monthsNumber}
                        onChange={this.onChange}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onBlur={this.onMonthsNumberChange}
                        className="output input-r"
                      />
                    </div>
                    {monthsNumberError ? <div className="hint error">{monthsNumberError}</div> : ''}
                  </div>
                </div>
                <div className="row">
                  <div className="cell-7">
                    <div className="as-input row below">
                      <span className="label cell">{I18n.t('purchase_orders.form.end_date')}</span>
                      <div className="output cell number">{formattedEndDate()}</div>
                    </div>
                  </div>
                  <div className="cell-9">
                    <div className="as-input row below">
                      <span className="label cell">{I18n.t('purchase_orders.form.monthly_cost')}</span>
                      <div className="output cell number">{formattedCost(monthlyCost)}</div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            {purchaseOrder.custom_po_enabled
            && (purchaseOrder.type === 'customer_leasing' || purchaseOrder.type === 'third_party_resale') ? (
              <React.Fragment>
                <div className="row">
                  <div className={`offset-7 cell-9 ${customerUpliftError ? 'has-error' : ''}`}>
                    <div className="as-input reduce required">
                      <span className="label">{I18n.t('purchase_orders.form.customer_uplift')}</span>
                      <input
                        type="number"
                        disabled={!purchaseOrder.editable}
                        name="customerUplift"
                        placeholder={I18n.t('purchase_orders.form.customer_uplift')}
                        value={customerUplift}
                        onChange={this.onChange}
                        onBlur={this.onCustomerLiftChange}
                        className="output input-r"
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                    {customerUpliftError ? <div className="hint error">{customerUpliftError}</div> : ''}
                  </div>
                </div>
                {purchaseOrder.type === 'customer_leasing' ? (
                  <div className="row">
                    <div className="offset-7 cell-9">
                      <div className="as-input row below">
                        <span className="label cell">{I18n.t('purchase_orders.form.monthly_sales_price')}</span>
                        <div className="output cell number">{formattedCost(monthlySalesPrice)}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="offset-7 cell-9">
                      <div className="as-input row below">
                        <span className="label cell">{I18n.t('purchase_orders.form.one_off_sales_price')}</span>
                        <div className="output cell number">{formattedCost(oneOffSalesPrice)}</div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
              ) : null}
            {customFieldSettings.length > 0
              && customFieldSettings.map(customFieldSetting => (
                <CustomField
                  key={`custom-field-setting-${customFieldSetting.id}}`}
                  purchaseOrder={purchaseOrder}
                  customFieldSetting={customFieldSetting}
                  onChange={this.onCustomFieldChange}
                />
              ))}
            <div className="row">
              <div className="cell-14">
                <label className="label" htmlFor="autosend">
                  {I18n.t('purchase_orders.form.auto_send_after_approve')}
                </label>
              </div>
              <div className="cell-2">
                <label className="radio-switch">
                  <input
                    id="autosend"
                    disabled={!purchaseOrder.editable}
                    type="checkbox"
                    name="autosend"
                    checked={autosend}
                    onChange={this.onAutosendChange}
                  />
                  <span />
                </label>
              </div>
              {autosendError ? <span>{autosendError}</span> : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InternalInfo;
