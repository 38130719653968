import React from 'react';
import { isEmpty } from 'lodash';
import SupplierService from '@/services/supplier_service';
import PasteLink from './paste_link';

export default function PasteSuppliersLink() {
  const placeholder = 'Supplier number\tName\tVAT number\tAddress\tAddress Line 2'
                      + '\tZip code\tCity\tState/Province\tCountry\tEmail\tCurrency'
                      + '\tPayment terms Id\tDelivery terms\tBank account number\tPDF language\n';

  const fieldsMap = {
    id: {
      columns: ['supplier id', 'supplierid', 'id'],
      label: I18n.t('suppliers.partials.form.internal_id'),
    },
    supplier_number: {
      columns: ['supplier number', 'supplier_number', 'suppliernumber', 'number'],
      label: I18n.t('suppliers.partials.form.supplier_number'),
    },
    name: {
      columns: ['supplier', 'supplier name', 'supplier_name', 'suppliername', 'name', 'title', 'description'],
      label: I18n.t('suppliers.partials.form.name'),
      constraints: { presence: { allowEmpty: false } },
    },
    vat_number: {
      columns: ['vat number', 'vat_number', 'vatnumber', 'vat'],
      label: I18n.t('suppliers.partials.form.vat_number'),
    },
    street: {
      columns: ['address', 'street', 'address_line1', 'address line1', 'address1', 'address_1', 'address_line_1', 'address line 1'],
      label: I18n.t('suppliers.partials.form.address'),
    },
    address_line2: {
      columns: ['address line 2', 'address line2', 'address_line2', 'address_line_2', 'address line 2'],
      label: I18n.t('suppliers.partials.form.address_line2'),
    },
    address_line3: {
      columns: ['address line 3', 'address line3', 'address_line3', 'address_line_3', 'address line 3'],
      label: I18n.t('suppliers.partials.form.address_line3'),
    },
    zip_code: {
      columns: ['zip code', 'zip_code', 'zipcode', 'zip', 'postcode', 'post code'],
      label: I18n.t('suppliers.partials.form.zip_code'),
    },
    city: {
      columns: ['city'],
      label: I18n.t('suppliers.partials.form.city'),
    },
    state: {
      columns: ['state province', 'stateprovince', 'state_province', 'state/province', 'state', 'province'],
      label: I18n.t('suppliers.partials.form.state'),
    },
    country: {
      columns: ['country'],
      label: I18n.t('suppliers.partials.form.country'),
    },
    email: {
      columns: ['email', 'e-mail', 'mail'],
      label: I18n.t('suppliers.partials.form.email'),
      constraints: (_value, attributes) => {
        if (isEmpty(attributes.email)) return null;
        return {
          email: true,
        };
      },
    },
    phone_number: {
      columns: ['phone_number', 'phonenumber', 'phone number', 'phone'],
      label: I18n.t('suppliers.partials.form.phone_number'),
    },
    currency: {
      columns: ['currency'],
      label: I18n.t('suppliers.partials.form.currency'),
      constraints: { presence: { allowEmpty: false } },
    },
    bank_account_number: {
      columns: ['bank_account_number', 'bank account number', 'bankaccountnumber', 'bankaccount', 'bank account'],
      label: I18n.t('suppliers.partials.form.bank_account_number'),
    },
    pdf_language: {
      columns: ['pdf_language', 'pdf language', 'pdflanguage', 'pdf', 'language'],
      label: I18n.t('suppliers.partials.form.pdf_language'),
      constraints: { inclusion: ['', 'en', 'nb', 'fe', 'de'] },
    },
    payment_term_id: {
      columns: ['payment_terms_id', 'payment terms id', 'paymenttermsid', 'payment'],
      label: I18n.t('suppliers.partials.form.payment_terms_id'),
    },
    delivery_terms: {
      columns: ['delivery_terms', 'delivery terms', 'deliveryterms', 'delivery'],
      label: I18n.t('suppliers.partials.form.delivery_terms'),
    },
  };

  const importSuppliers = suppliers => SupplierService.importAll(suppliers);

  return (
    <PasteLink
      type="suppliers"
      templateUrl="/suppliers.xlsx"
      placeholder={placeholder}
      fieldsMap={fieldsMap}
      onImport={importSuppliers}
      afterImport="reload"
    >
      <a className="link">
        { I18n.t('purchase_orders.form.paste_items.import') }
      </a>
    </PasteLink>
  );
}
