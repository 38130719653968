import React from 'react';
import EditableRow from './EditableRow';

const EditableTable = ({
  columns,
  data,
  onSave,
  onDelete,
  isLoading,
  setIsAdding,
  isAdding,
  editingId,
  setEditingId,
}) => {
  const isInOperation = isAdding || editingId;
  const dotsNumber = columns.length + 1;
  const tableName = 'table';

  const handleEdit = (id) => {
    if (isInOperation) return;
    setEditingId(id);
  };

  const handleSave = async (formData) => {
      await onSave(formData);
      resetForm();
  };

  const resetForm = () => {
    setEditingId(null);
    setIsAdding(false);
  };

  // renderInputs and renderNav is required to use existing styles to make responsive design.
  const renderInputs = () => {
    return Array.from({ length: dotsNumber }, (_, i) => (
      <input 
        key={i}
        type="radio"
        name={tableName}
        id={`${tableName}_${i + 1}`}
        defaultChecked={i === 0}
      />
    ));
  };

  const renderNav = () => {
    return (
      <div className="table-nav">
        {Array.from({ length: dotsNumber }, (_, i) => (
          <label key={`label_${i}`} htmlFor={`${tableName}_${i + 1}`} />
        ))}
        {Array.from({ length: dotsNumber }, (_, i) => (
          <div key={`dot_${i}`} className="dot" />
        ))}
      </div>
    );
  };

  return (
    <div className={`table-fluid show-last table-scrollable scroll-init table-column-${dotsNumber}`}>
      {renderInputs()}
      <div className="table-header">
        {renderNav()}
      </div>
      <div className="table">
        <div className="thead">
          <div className="tr">
            {columns.map(column => (
              <div key={column.id} className="th">
                {column.label}
              </div>
            ))}
            <div className="th"></div>
          </div>
        </div>
        
        <div className="tbody">
          {data.map(row => (
            <EditableRow
              key={row.id}
              row={row}
              columns={columns}
              isEditing={editingId === row.id}
              onSave={handleSave}
              onCancel={resetForm}
              onEdit={() => handleEdit(row.id)}
              onDelete={() => onDelete(row.id)}
              isLoading={isLoading}
              isInOperation={isInOperation}
            />
          ))}
          {isAdding && (
            <EditableRow
              row={columns.reduce((acc, col) => ({ ...acc, [col.id]: '' }), {})}
              columns={columns}
              isEditing={true}
              onSave={handleSave}
              onCancel={resetForm}
              isLoading={isLoading}
              isInOperation={isInOperation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditableTable; 