import React from 'react';
import PropTypes from 'prop-types';
import { parseDate } from '@/tools/index';

class Attachment extends React.Component {
  static propTypes = {
    category: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
    deletable: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.moveTo = this.moveTo.bind(this);
    this.delete = this.delete.bind(this);

    const { category } = props.attachment;
    this.state = {
      category,
    };
  }

  delete() {
    const { deleteAttachment } = this.props;
    deleteAttachment(this);
  }

  moveTo() {
    const { props: { moveAttachmentTo }, state: { category } } = this;
    moveAttachmentTo(category == 'internal' ? 'external' : 'internal', this);
  }

  render() {
    const {
      props: {
        attachment: {
          name, url, created_at, deletable,
        },
        style,
        moveAttachmentTo,
        openConfirmationModal,
        disconfirmOrder,
      },
      state: {
        category,
      },
    } = this;
    return (
      <li className="item">
        <div className="anchor">
          <div className="has-popup">
            <input type="checkbox" />
            <a className="more">
              <i className="icon-more" />
            </a>
            <ul className="popup bottom-right menu">
              {
                moveAttachmentTo
                  && (
                  <li className="item">
                    <a className="anchor" onClick={this.moveTo}>
                      <i className={`icon-${style}`} />
                      <span>
                        {I18n.t('purchase_orders.form.move_to')}
                        {' '}
                        {category == 'internal' ? 'external' : 'internal'}
                      </span>
                    </a>
                  </li>
                  )
              }
              {
                openConfirmationModal
                  && (
                  <li className="item">
                    <a className="anchor" onClick={openConfirmationModal}>
                      <i className="icon-edit" />
                      <span>
                        {I18n.t('purchase_orders.form.edit')}
                      </span>
                    </a>
                  </li>
                  )
              }
              { deletable ? (
                <li className="item">
                  <a className="anchor" onClick={this.delete}>
                    <i className="icon-delete_forever" />
                    <span>{I18n.t('purchase_orders.form.remove')}</span>
                  </a>
                </li>
              ) : null
              }
              { disconfirmOrder ? (
                <li className="item">
                  <a className="anchor" onClick={disconfirmOrder}>
                    <i className="icon-delete_forever" />
                    <span>{I18n.t('purchase_orders.form.remove')}</span>
                  </a>
                </li>
              ) : null
              }
            </ul>
          </div>
          <i className={`icon-${style}`} />
          <a href={url} target="_blank">{name}</a>
          <span>{parseDate(created_at)}</span>
        </div>
      </li>
    );
  }
}

export default Attachment;
